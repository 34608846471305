import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";

class Countries extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            buyerProcess: false,
            showMessage: false
        }

        this.props.changeFullScreen(false);
    }

    changeBuyerProcess(state) {
        this.setState({
            buyerProcess: state
        });
    }

    buyNow() {
        this.setState({
            showMessage: true
        })
    }

    downloadFile(url, filename) {
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // This forces the browser to download the file
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after the download
    }

    yes() {
        this.downloadFile('/images/TeamViewer.pdf', 'TeamViewer.pdf');
        this.downloadFile('/images/attachment.pdf', 'attachment.pdf');
        // window.open(`/images/TeamViewer.pdf`, '_blank');
        // window.open(`/images/attachment.pdf`, '_blank');
    }

    render() {
        return (
            <div id={'main-page'}>
                <Grid container spacing={24}>
                    <div style={{
                        'minHeight': '80vh',
                        'max-width': '900px',
                        'margin': '0 auto',
                        'padding': '50px 0',
                        'width': '100%'
                    }}>
                        <p>US<br/>Germany<br/>France<br/>Italy<br/>Serbia<br/>Russia</p>
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps(
    {
        menuReducers, authReducers
    }
) {
    return {menu: menuReducers, user: authReducers.user};
}

// export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Countries));

import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";

class Buyer extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            buyerProcess: false,
            showMessage: false
        }

        this.props.changeFullScreen(false);
    }

    changeBuyerProcess(state) {
        this.setState({
            buyerProcess: state
        });
    }

    buyNow() {
        this.setState({
            showMessage: true
        })
    }

    downloadFile(url, filename) {
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // This forces the browser to download the file
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after the download
    }

    yes() {
        this.downloadFile('/images/TeamViewer.pdf', 'TeamViewer.pdf');
        this.downloadFile('/images/attachment.pdf', 'attachment.pdf');
        // window.open(`/images/TeamViewer.pdf`, '_blank');
        // window.open(`/images/attachment.pdf`, '_blank');
    }

    render() {
        return (
            <div id={'main-page'}>
                <Grid container spacing={24}>
                    <div style={{
                        'minHeight': '80vh',
                        'max-width': '900px',
                        'margin': '0 auto',
                        'padding': '50px 0',
                        'width': '100%'
                    }}>
                        <p>Virtually every company encounters a problem when they buy an online software product in a
                            cross-border transaction. So, where’s the pain point? These transactions have significant
                            tax implications that hurt both the buyers and sellers.
                        </p>
                        <p>Buyers don’t really know the exact price they have to pay - their tax advisors often tells
                            them that after the fact, and they feel someone’s overcharging them. To understand this,
                            they need to pay expensive advisors. For buyers, check how we solved this from his
                            perspective: </p>

                        <Link to={'/how-it-works/buyer'}>Learn more</Link>

                        <p>Sellers, on the other hand, can’t optimize their tax burdens and lose upwards of 10k a year
                            on taxes they could avoid. This limits their competitiveness as they don’t know how to
                            define their pricing, and they have to spend time on administration to handle tax data.
                        </p>

                        <p>GTM teams that are calculating price sensitivity almost always forget to add taxes like
                            withholding tax on royalties or VAT that could affect buyer. Here we play a crucial role to
                            connect both worlds.
                        </p>

                        <p>Taxvoice is an ever-evolving digital tax advisor and invoice builder which automatically
                            calculates every applicable tax for any service transaction in any market.
                        </p>

                        <p>It saves buyers an average of 20% of gross cost loyalties and gives them complete information
                            on applicable taxes so they can do budgeting, tax planning or choosing the right product for
                            them.
                        </p>

                        <p>On the other hand sellers offer a more transparent service, can optimize their costs, pricing
                            and tax burden by moving across geographies - for instance, deciding to sell a license from
                            Spain instead of Germany to a specific customer - and save them time which they would spend
                            using multiple tools to do this.
                        </p>

                        <p>In our portfolio you can use multiple solutions to comunicate with your customer from
                            Freemium, Basic, Small business, Pro or API plan.
                        </p>

                        <img style={{margin: '20px 0', maxWidth: '100%'}} src={'/images/table.png'}/>

                        <p>We offer our smart Plug-in solution on sellers website so customer could preorder a full
                            transactional cost and check effective price. Part of this deal goes to you as a seller so
                            you can earn as well. If there is double taxation treaty, dont worry, we will let your
                            customer know what exact taxes in which due date he needs to pay so both of you could stay
                            complied. We will help you as a seller to provide all info to customer about complex
                            administration and documentation to use these benefits. Give it a try at freemium version
                            for 1 month.
                        </p>

                        <p>And yes, there is more!
                        </p>

                        <p>In Pro or API plan we have collected all of your data to do most complex international tax
                            advisory in just one click!
                        </p>

                        <p>By using data on your customer geo-location, earnings, royalties tax, VAT we can help you
                            strategically position in such countries where you can either be more cheaper to your
                            customer or raise prices and directly raise the profit!
                        </p>

                        <p>Here we will advise you about CIT, dividends and all other compliance including PEM (place of
                            effective management), Pillar 1 or 2 and newest rules on global corporate profit taxation
                            based on the residence.
                        </p>

                        <p>Our API solution is the most advanced solution for your company to use our data and get the
                            most of it. We will provide you with all the data you need to make the best decision for
                            your company.
                        </p>

                        <p>Case study:</p>

                        <p>Our secret sauce and how we do it:</p>

                        <p>Every invoice is made of type of the service that you provide to your clients. Simply, drag and drop services from our Menu to make an invoice - Taxvoice.
                            We take care about tax treatment of every service in 30+ countries so whenever any change in laws in every country or between country occurs we will update it in backend on your invoice right on time so your customers stay complied within latest tax info. This way we also collect data on your customers, volume of transactions, amounts and geo-location so we can help you understand your pricing and how and where to incorporate to invoice more or to be cheaper for your customer.</p>

                        <img style={{margin: '20px 0', maxWidth: '100%'}} src={'/images/Step1.png'}/>

                        <img style={{margin: '20px 0', maxWidth: '100%'}} src={'/images/Step1.png'}/>
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps(
    {
        menuReducers, authReducers
    }
) {
    return {menu: menuReducers, user: authReducers.user};
}

// export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buyer));

import React, {Component} from 'react'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {Divider, InputBase, makeStyles, TextField} from "@material-ui/core";
import TablePage from "../../common/TablePage";
import {AutoComplete} from "material-ui";
import Autocomplete from "../Autocomplete";
import {getError, hasError} from "../../functions/Validation";
import Button from "@material-ui/core/Button";
import {getAllCorStates} from "../../services/admin/CorStatesService";
import {sendEmail} from "../../services/UserService";
import {withSnackbar} from "notistack";

class SearchComponent extends TablePage {

    constructor(props) {
        super(props);

        let user = this.getUser();

        this.state = {
            data: {
                name : user ? user.first_name : '',
                email : user ? user.email : ''
            },
            searchResults: [
            ],
            searchData: {
                search : '',
                total : 0,
                page : 1,
                perPage : 30
            },
            seeAll: false,
            maxResults: 3
        }

        this.props.changeFullScreen(false);
        this.fetchData = this.fetchData.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.seeAllResults = this.seeAllResults.bind(this);
        this.boldSearch = this.boldSearch.bind(this);
    }

    fetchData() {
        getAllCorStates(this.state.searchData).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                searchResults: response.data.result,
                searchData : {
                    ...this.state.searchData,
                    page : response.data.page,
                    perPage : response.data.perPage,
                    total : Math.ceil(response.data.total/response.data.perPage)
                }
            });
        });
    }

    seeAllResults(){
        this.setState({
            seeAll: true
        })
    }

    boldSearch(text){

        var regex = this.state.searchData.search.length > 2 ? new RegExp("(" + this.state.searchData.search + ")", "gi") : 'null';
        var match = text.match(regex)

        if (match != null) {
            var parts = text.split(match[0], 2)

            return (
                <div>{parts[0]}<span style={{fontWeight: 'bold'}}>{match[0]}</span>{parts[1]}</div>
            );
        }
        else {
            return (
                <div>{text}</div>
            );
        }
    }

    renderResults(){
        let result = [];
        let searchData = this.state.searchResults;
        let seeAll = this.state.seeAll;
        let maxResults = this.state.seeAll ? this.state.searchResults.length : this.state.maxResults;
        let self = this;

        Object.keys(searchData).forEach(function(key) {
            if(key < maxResults){
                result.push(
                    <div>
                        <h3>{self.boldSearch(searchData[key].name)}</h3>
                        <Divider />
                        <p className={'search-description'}>{self.boldSearch(searchData[key].description)}</p>
                    </div>
                );
            }
        });

        if(!seeAll){
            result.push(
                <div>
                    <Divider />
                    <p className={'see-all'} onClick={this.seeAllResults}>{strings.search.seeAll}</p>
                    <Divider />
                </div>
            )
        }

        return result;
    }

    renderNoResults(){
        return(
            <div>
                <p>{ strings.search.noResults }</p>
                <TextField
                    fullWidth
                    placeholder={strings.search.enterName}
                    name='name'
                    onChange={ this.changeData }
                    margin="normal"
                    value={ this.state.data.name }
                    className={'enter-name'}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
                <TextField
                    fullWidth
                    placeholder={strings.search.enterEmail}
                    name='email'
                    onChange={ this.changeData }
                    margin="normal"
                    value={ this.state.data.email }
                    className={'enter-email'}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
                <Button variant="contained" color="secondary" onClick={() => {
                    sendEmail({
                        search : this.state.searchData.search,
                        name : this.state.data.name,
                        email : this.state.data.email
                    }).then(response => {
                        if (!response.ok){
                            this.props.enqueueSnackbar('All fields required', { variant: 'error' });
                            return
                        }

                        this.props.enqueueSnackbar('Successfully added', { variant: 'success' });
                    })
                }}>
                    { strings.search.send }
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Grid container spacing={24}>
                    <div className={'search-wrapper'}>
                        <img src={'/images/Ilustracija81.svg'} />
                        <div className={'search-bar-wrapper'}>
                            <h1>{ strings.home.mainTitle }</h1>
                            <p className={'description'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer consectetur tellus nisl vivamus at etiam est blandit eu. Quam auctor egestas nec, faucibus quis. Nulla id eget cursus pellentesque ridiculus,</p>
                            <div>
                                <InputBase
                                    placeholder={ strings.home.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    className={'search-bar'}
                                    inputProps={{ 'aria-label': 'naked' }}
                                />
                                <p>Beta phase: Currently works with words "Trello" "Clockify" "TeamViewer" "Serbia" "Germany" "Consulting" "Database"</p>
                                {
                                    this.state.searchData.search.length !== 0 &&
                                    <div className={'search-results-wrapper'}>
                                        {
                                            this.state.searchResults.length !== 0
                                            ?
                                            <div className={'search-results'}>
                                                {this.renderResults()}
                                            </div>
                                            :
                                            <div className={'no-results'}>
                                                {this.renderNoResults()}
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <a href={'/login'}>{ strings.home.login }</a>
                        </div>
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchComponent)));
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchComponent));
